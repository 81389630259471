<template>
  <div class="Box">
    <audio ref="audioRef" :src="musicSource" controls @timeupdate="findIndex"></audio>
    <div class="contentBox" ref="contentBoxRef">
      <ul>
        <li v-for="(item,index) in wordTimeAndList" :key="item.time" :class="{active: index === activeIndex }">{{ item.word }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import {musicStr} from '/src/assets/Other/MusicStr'
export default {
  name: "歌词滚动效果",
  data() {
    return {
      musicStr,
      musicSource: require('@/assets/Other/NanNianDeJing.mp3'),
      wordTimeAndList:[],
      activeIndex: null,
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    setOffset(index) {
      let fatherHeight = this.$refs.contentBoxRef.offsetHeight
      let liHeight = this.$refs.contentBoxRef.children[0].children[0].clientHeight
      let offsetNum = ((liHeight * index) + (liHeight / 2) - (fatherHeight / 2))
      offsetNum = offsetNum > 0 ? offsetNum : 0
      this.$refs.contentBoxRef.children[0].style.transform = `translateY(-${offsetNum}px)`
      console.log('offsetNum--',offsetNum,index)
    },
    findIndex() {
      let currentTime = this.$refs.audioRef.currentTime
      let moreIndex = this.wordTimeAndList.findIndex(e => {
        return e.time > currentTime
      })
      let getIndex = moreIndex > 0 ? moreIndex - 1 : (this.wordTimeAndList.length - 1)
      this.activeIndex = getIndex
      if(getIndex >= 0) {
        this.setOffset(getIndex)
      }
    },
    init() {
      let musicArr = musicStr.split('\n')
      let wordObjArr = musicArr.map(e => {
        let arr = e.split(']')
        let timeStr = arr[0].substring(1)
        let timeList = timeStr.split(':')
        let min = Number(timeList[0])
        let sec = Number(timeList[1])
        let secData = (min * 60) + sec
        return {
          time: secData,
          word: arr[1]
        }
      })
      this.wordTimeAndList = wordObjArr
      console.log(this.wordTimeAndList)
    },

  }
}
</script>

<style lang="scss" scoped>
.Box {
  background-color: #000;
  color: #666;
  text-align: center;
}
.contentBox{
  height: 420px;
  overflow: hidden;
}
.contentBox ul {
  list-style: none;
  transition: 0.2s;
}

.contentBox li {
  height: 30px;
  line-height: 30px;
  transition: 0.2s;
}
.contentBox li.active {
  color: #fff;
  transform: scale(1.2);
}
audio{
  width: 450px;
  margin: 30px 0;
}
</style>
