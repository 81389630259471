<template>
  <div>
    无代码，为了解释事件循环，微队列执行顺序
  </div>
</template>

<script>
export default {
  name: "面试题汇总",
  mounted() {
    Promise.resolve().then(() => {
      console.log(0)
      return Promise.resolve(4)
    })
    .then((res) => {
      console.log(res)
    })
    Promise.resolve().then(() => {
      console.log(1);
    }).then(() => {
      console.log(2);
    }).then(() => {
      console.log(3);
    }).then(() => {
      console.log(5);
    }).then(() => {
      console.log(6);
    })
  }
}
</script>

<style scoped>

</style>
