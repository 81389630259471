import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "contentBox" }
const _hoisted_2 = { class: "ulBox" }
const _hoisted_3 = ["onClick"]

import Notice from "@/views/Study/component/notice.vue";
import SetupDemo from './component/SetupDemo.vue'
import ES6Demo from './component/ES6相关与技术基础.vue'
import EventLoop from './component/WebBigTeacher/事件循环.vue'
import ReflowAndRepaint from './component/WebBigTeacher/浏览器渲染原理.vue'
import SongWord from './component/WebBigTeacher/歌词滚动效果.vue'
import Question from './component/WebBigTeacher/面试题汇总.vue'
import PropertyDesc from './component/WebBigTeacher/属性描述符.vue'
import {ref,shallowRef} from "vue";
type content = {id: string, title:string,comp:unknown}

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

let contentArr = ref<content[]>()
let showContent = ref<content>()
contentArr.value = [{
  id: '1',
  title: '笔记',
  comp: shallowRef(Notice)
},{
  id: '2',
  title: 'Setup试用',
  comp: shallowRef(SetupDemo)
},{
  id: '3',
  title: 'ES6相关与技术基础',
  comp: shallowRef(ES6Demo)
},{
  id: '4',
  title: '大师课-事件循环',
  comp: shallowRef(EventLoop)
},{
  id: '5',
  title: '大师课-浏览器渲染原理',
  comp: shallowRef(ReflowAndRepaint)
},{
  id: '6',
  title: '大师课-歌词滚动效果',
  comp: shallowRef(SongWord)
},{
  id: '7',
  title: '大师课-面试题汇总',
  comp: shallowRef(Question)
},{
  id: '8',
  title: '大师课-属性描述符',
  comp: shallowRef(PropertyDesc)
}]
// contentArr.value = []
function clickTitle(item:content) {
  showContent.value = item
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(contentArr), (item) => {
          return (_openBlock(), _createElementBlock("li", {
            key: item.title,
            onClick: ($event: any) => (clickTitle(item)),
            style: _normalizeStyle({color: _unref(showContent) && _unref(showContent).title == item.title ? ' #007aff' : ''})
          }, _toDisplayString(item.title), 13, _hoisted_3))
        }), 128))
      ])
    ]),
    (_unref(showContent) && _unref(showContent).comp)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(showContent).comp), { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}
}

})