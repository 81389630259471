<template>
  <div>
    <h1>数据运算</h1>
    <h3>一、算术运算: + - * / % ++ --</h3>
    <p>先转换为原始类型，再转换为数字进行运算。特殊情况如下：</p>
    <ul>
      <li>x+y,x或y有一个是字符串，x和y都转为字符串，然后拼接</li>
      <li>NaN和任何数据运算都获得NaN</li>
    </ul>
    <h3>练习题</h3>
    <ul>
      <li>
        问题:let a = ? ，可以使, console.log(a == 1 && a == 2 && a == 3) 成立?
        <p>答案: let a = {
          n: 0,
          valueOf() {
          return this.n += 1
          }
          }
          <br/>
          思路：对象进行比较运算，先转为原始类型，转换原始类型会调用valueOf方法，因此，通过重写valueOf方法，来使返回的值自增
        </p>
      </li>
      <li>NaN和任何数据运算都获得NaN</li>
    </ul>
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped>

</style>
