<template>
  <div>
    我是属性描述符，请看控制台
  </div>
</template>

<script>
export default {
  name: "属性描述符",
  mounted() {
    let obj = {
      a: 1,
      b: 2,
      c: {
        cc: 'cc是我'
      }
    }
    class Demo {
      constructor(a) {
        let demoData;
        Object.defineProperty(this,'data',{
          get() {
            console.log('get')
            return demoData
          },
          set(v) {
            console.log('set')
            demoData = v
          }
        })
      }
    }
    let demo = new Demo(1)
    let demo2 = new Demo(1)
    demo.data = 33
    demo2.data = 44
    console.log(demo)
    console.log(demo2)
    /*Object.defineProperty(obj.c,'cc',{
      get() {
        return 'ccc'
      },
      set(v) {
        console.log("我不要" + v + '我有自己的想法')
      }
    })
    obj.c.cc = 5
    console.log(obj)*/
  }
}
</script>

<style scoped>

</style>
