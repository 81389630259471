export const musicStr = `[00:00.00]郭顶 - 凄美地
[00:00.26]作词：郭顶
[00:00.83]作曲：郭顶
[00:03.54]曾经我是不安河水
[00:07.36]穿过森林误入你心
[00:10.24]没计划扎营
[00:12.19]搁下了是非
[00:14.05]一去不回
[00:18.67]如今我是造梦的人呐
[00:22.45]怅然若失流连忘返啊
[00:25.62]等潮汐来临
[00:27.52]我就能记起
[00:29.48]你的样子
[00:33.54]我没看过
[00:37.18]平坦山丘
[00:40.97]怎么触摸
[00:44.84]开花沼泽
[00:48.63]嘿 等我找到你
[00:54.31]试探你眼睛
[00:58.23]心无旁骛地 相拥
[01:03.08]那是我 仅有的温柔
[01:08.26]也是我爱你的原因
[01:12.57]在这凄美地
[01:31.69]曾经这里是无人之地
[01:35.41]为何没留下有效地址
[01:38.49]肆意的消息
[01:40.37]迷失在十月
[01:42.37]没有音讯
[01:46.93]如今这里是风和日丽
[01:50.75]等你再回来雨过迁徙
[01:53.89]看夜幕将近
[01:55.85]我又能记起
[01:57.75]你的样子
[02:01.81]我还记得
[02:05.49]平坦山丘
[02:09.30]如今身在
[02:13.20]开花沼泽
[02:17.02]嘿等我找到你
[02:22.72]试探你眼睛
[02:26.48]心无旁骛地 相拥
[02:31.31]那是我 仅有的温柔
[02:36.65]也是我爱你的原因
[02:40.91]在这凄美地
[02:45.10]在这之前
[02:46.86]别说再见
[02:48.94]我已再经不起离别
[02:52.70]在这之前
[02:54.72]别说再见
[02:56.61]我已经开始了想念
[03:00.35]在这之前
[03:02.25]别说再见
[03:04.27]请帮我停住这时间
[03:07.64]就这样 别安慰
[03:14.70]嘿 等我找到你
[03:20.33]望住你眼睛
[03:24.11]心无旁骛地 相拥
[03:28.99]那是我 仅有的温柔
[03:34.12]也是我爱你的原因
[03:38.58]如此不可及
[03:42.48]如此不思议
[03:46.21]让我坠落
[03:47.22]在这凄美地`

