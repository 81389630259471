<template>
  <div>
    <button @click="numAdd">点击+1</button><br/>
    点击了{{num}}下
  </div>
</template>

<script>
import {ref} from "vue";

export default {
  setup() {
    let num = ref(0)
    function numAdd() {
      num.value = num.value + 1
    }
    return {
      num,
      numAdd
    }
  }
}
</script>

<style scoped>

</style>
