<template>
  <div>

    <div>
      <h1>标签模板</h1>
      <p>标签模板其实不是模板，而是函数调用的一种特殊形式。“标签”指的就是函数，紧跟在后面的模板字符串就是它的参数。</p>
      <pre>
        alert`hello`
        // 等同于
        alert(['hello'])
      </pre>

      <pre>
        let a = 5;
        let b = 10;

        tag`Hello ${ a + b } world ${ a * b }`;
        // 等同于
        tag(['Hello ', ' world ', ''], 15, 50);
      </pre>
      <p>
        tag函数的第一个参数是一个数组，该数组的成员是模板字符串中那些没有变量替换的部分，也就是说，变量替换只发生在数组的第一个成员与第二个成员之间、第二个成员与第三个成员之间，以此类推
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ES6相关与技术基础",
  mounted() {
    this.c()
    console.log(666)
    let a = 'aaa'
    this.stringTemplate`1${a}`
  },
  methods: {
    stringTemplate(str,a) {
      console.log('stringTemplate--',str,a)
    },
    a() {
      console.log(111)
      return 222
    },
    b() {
      console.log(333)
      return new Promise((resolve) => {
        console.log(444)
        resolve(5555)
      })
    },
    async c() {

      let aaa = await this.a()
      let bbb = await this.b()
      console.log(aaa)
      console.log(bbb)
    },
  }
}
</script>

<style scoped>

</style>
