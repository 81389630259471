<template>
  <div>
    <h1>浏览器是如何渲染页面的</h1>
    <p>当浏览器的网络线程收到html文档后，会产生一个渲染任务，并将其选给渲染主线的消息队列，根据事件循环机制的作用下，渲染主线程取出消息队列中的渲染任务，开启渲染进程</p>
    <p>整个渲染流程分为多个阶段，分别是：HTML解析、样式计算、布局、分层、绘制、分块、光栅化、画。每个阶段都有明确的输入输入，上一个阶段的输出会成为下一个阶段的输入。</p>

    <h2>渲染的第一步是解析HTML</h2>

    <p>解析过程中遇到CSS解析css，遇到js执行js。为了提高解析效率。浏览器在开始解析前，会启动一个预解析的线程，率先下载HTML中的外部css文件和外部js文件</p>
    <p>如果主线程解析到link位置，此时外部的css文件还没有下载解析好，主线程不会等待，继续解析后续的html。这是因为下载和解析css的工作是在预解析线程中进行的。这就是css不会阻塞html解析的根本原因</p>
    <p>如果主线程解析到script的位置，会停止解析html。转而等待js文件下载好，并将全局代码解析执行完成后，才能继续解析html。这是因为js代码的执行过程可能会修改当前的dom树。所以dom树的生成必须暂停。这就是js会阻塞html解析的根本原因。</p>
    <p>第一步完成后，会得到dom树和cssom树，浏览器的默认样式、内部样式、外部样式、行内样式均会包含在cssom树中</p>
    <h2>第二步是样式计算</h2>
    <p>dom树和cssom树中的样式进行计算，将em计算为px，red变为rgb(255,0,0) </p>
    <h2>第三步是布局</h2>
    <p>
      布局阶段会依次遍历dom树的每一个节点，计算节点的几何信息。例如节点的宽高，相对包含块的位置
      dom树和layout树不一定是一一对应的，比如隐藏起来的元素就不会出现在layout树上；
      伪元素不出现在dom树上，但layout树会存在
    </p>
    <h2>第四部分层-layer</h2>
    <p>
      主线程会使用一套复杂的策略对整个布局树中进行分层，分层的好处在于，将来某一个层改变后，仅会对该层进行后续处理，从而提升效率。
      滚动条、堆叠上下文、transform、opacity等样式都或多或少影响分层结果，也可以通过will-change属性更大程度的影响分层结果。
    </p>
    <h2>第五部分绘制</h2>
    <p>渲染主线程的工作到此为止，剩余步骤交给其他线程完成。这里的分层，是为每一层生成如何绘制的指令</p>
    <h2>分块</h2>
    <p></p>
    <p></p>
    <h2>第八步 画</h2>
    <h3>transform在第八步</h3>

    <h2>面试题：什么是reflow（回流）</h2>
    <p>reflow的本质就是重新计算layout树，当进行了会影响布局树的操作后（比如改动节点的几何信息，宽、高、删除dom节点、修改文字大小等等），需要重新计算布局树，会引发layout.为了避免连续的多次操作导致布局树反复计算，浏览器会合并这些操作，当js代码全部完成后再进行统一计算。所以
    改动属性早晨的reflow是一部完成的。也是同样因为如此，当js获取布局属性时，就可能造成无法获取到最新的布局信息。浏览器在反复权衡下，最终决定获取属性立即reflow。</p>
    <h2>什么是repaint? （重绘）</h2>
    <p>repaint的本质就是重新根据分层信息计算了绘制指令，当改动了可见样式后(比如改动颜色)，就需要重新计算，会引发repaint,由于元素的布局信息也属于可见样式，所以reflow一定会引起repaint</p>
    <h2>transform为什么效率高</h2>
    <p>因为transform不会引起回流，仅影响重绘的最后一步（画），所以效率高</p>
  </div>
</template>

<script>
export default {
  name: "浏览器渲染原理"
}
</script>

<style scoped>

</style>
