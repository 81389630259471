<template>
  <div>
    <h1>浏览器的进程模型</h1>
    <div>
      <h2>何为进程</h2>
      <p>程序运行需要有他自己专属的内存空间，可以把这块内存空间简单的理解为进程</p>
      <h2>何为线程</h2>
      <p>
        运行代码的'人'称之为'线程'，一个进程至少有一个线程，所以进程开启后会自动创建一个线程来运行代码，称之为'主线程'
        如果线程需要同时执行多块代码，主线程就会启动更多的线程来执行代码，所以一个进程中可以包含多个线程
      </p>
      <h2>浏览器是一个多进程多线程的应用程序</h2>
      <p>为了避免相互影响，为了减少连环崩溃的几率，当启动浏览器后，会自动启动多个进程</p>
      <p>其中主要的进程有
        1.浏览器进程：主要负责界面显示，用户交互，子进程管理等。浏览器进程内部会启动多个线程处理不同的任务
        2.网络进程：负责加载网络资源，网络进程内部会地洞多个线程来处理不同的网络任务
        3.渲染进程：先让进程启动后会开启一个渲染朱先生，主线程负责执行  HTML、CSS、JS代码，默认情况下，浏览器会为每个标签页开启一个新的渲染进程，以保证不同的标签页之间不互相影响
      </p>
    </div>
    <h1>渲染主线程是如何工作的</h1>
    <div>
      <p>渲染主线程是浏览器中最繁忙的线程，需要他处理的任务包括但不限于以下：</p>
      <ul>
        <li>解析HTML</li>
        <li>解析Css</li>
        <li>计算样式</li>
        <li>布局</li>
        <li>处理图层</li>
        <li>每秒把页面画60次  FPS</li>
        <li>执行全局JS代码</li>
        <li>执行事件处理函数</li>
        <li>执行计时器的回调函数</li>
        <li>...</li>
      </ul>
      <img src="事件队列.png" style="width: 400px">
      <p>
        1. 最开始时，渲染主线程会进入一个无限循环
        2. 每一次循环会检查消息队列中是否有任务存在。如果有，就取出第一个任务执行，执行完一个后进入下一次循环，如果没有，则进入休眠状态
        3. 其他所有线程（包括其他进程的线程）可以随时向消息队列添加任务。新任务会加到消息队列的末尾。在添加新任务时，如果主线程是休眠状态，则会将其唤醒以继续循环拿取任务
      </p>
      <div style="border: 1px solid red">
        <h2>如何理解JS的异步</h2>
        <p>
          答案：JS是一门单线程的语言，这是因为它运行在浏览器的渲染主线程中，而渲染主线程只有一个，而渲染主线程承担着诸多的工作，渲染页面、执行JS都在其中运行。
          如果使用同步的方式，就极有可能导致主线程产生阻塞，从而导致消息队列中的很多其他任务无法得到执行。这样依赖，一方面会导致繁忙的主线程白白小号事件，另一方面导致页面无法及时更新，给用户早晨卡死现象。
          所以浏览器采用异步的方式来避免。具体做法是当某些任务发生时，比如计时器、网络、事件监听，主线程将任务交给其他线程去处理，自身立即结束任务的执行，转而执行后续代码。当其他线程完成时，将事先传递的回调函数包装成任务，加入到消息队列的末尾排队，等待主线程调度执行。
          在这种异步模式下，浏览器永不阻塞，从而最大限度的保证了单线程的流畅运行。
          注意：一个标签页会启动一个渲染进程，渲染进程会启动一个渲染主线程，当前页面的渲染都由这个渲染主线程控制（单线程指的就是这个线程）
        </p>
        <h2>JS为什么会阻塞渲染</h2>
        <p>
          因为JS是单线程的语言，执行js时，如果js未执行完渲染主线程不能执行下一个任务，所以常常会出现，用js给页面赋值，但因设置了循环，js未执行完页面无法正常渲染的问题。
        </p>
      </div>
      <p>任务没有优先级，在消息队列中先进先出，但消息队列有优先级。每一个任务都有任务类型，同一个类型的任务必须在同一个消息队列中，不同类型的任务可以分属不同的队列。目前chrome实现中，至少包含了下面的队列：</p>
      <ul>
        <li>延时队列：用于存放计时器到达后的回调任务，优先级（中）</li>
        <li>交互队列：用于存放用户操作后产生的事件处理任务，优先级（高）</li>
        <li>微队列：用于存放需要最快执行的任务，优先级（最高） 比如promise产生的回调</li>
      </ul>
      <h1>面试题：阐述一下，JS的事件循环</h1>
      <p>
        参考答案：事件循环，又叫做消息循环，是浏览器渲染主线程的工作方式，在chrome的源码中，他开启一个不会节数的for循环，每次循环从消息队列中取出第一个任务执行，而其他线程只需要在核实的时候将任务加入到队列末尾即可。过去把消息队列简单分为宏队列和微队列。
        这种说法目前已无法满足复杂的浏览器环境。取而代之的是一种更加灵活多变的处理方式。
        根据w3c官方的解释，每个任务有不同的类型，同类型的任务必须在同一个队列，不同的任务可以属于不同的队列，不同任务队列有不同的优先级，在一次事件循环中，由浏览器自行觉得取哪一个队列的任务。
        但浏览器必须有一个微队列，微队列的任务一定具有最高的优先级，必须优先调度执行
      </p>

      <h1>面试题：JS中的计时器能做到精确计时吗？为什么？</h1>
      <p>
        答案：不行，因为1.计算机硬件没有原子钟，无法做到精确及时  2.操作系统的计时函数本身就有少量偏差，由于js的计时器最终调用的是操作系统的函数，也就携带了这些偏差  3.按照w3c的标准，浏览器实现计时器时，如果嵌套层级超过5层，则会有4毫秒的最少时间，这样在计时时间少于4毫秒时，又带来了偏差
        4.受事件循环的影响，计时器的回调函数只能在主线程空闲时运行，因此又带来了偏差
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "事件循环"
}
</script>

<style scoped>

</style>
